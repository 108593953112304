
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import ProductResource from '@/resources/ProductResource';
import Form from '@/components/Product/Form.vue';
import useForm from '@/composables/use-form';

export default defineComponent({
    components: { Form },

    setup() {
        const router = useRouter();
        const product = {
            inventory: {
                qty: null,
                unit: null,
            },
            media: [],
            uploaded_images: [],
        };
        const form = useForm(product, ProductResource);
        const { isSaving, save  } = form;

        function submit() {
            save().then(() => {
                router.go(-1);
            });
        }

        return {
            form,
            isSaving,
            submit,
        };
    },
});
